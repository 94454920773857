// src/App.js
import { Route, Routes } from 'react-router-dom';
import HomeRouter from './router/HomeRouter';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path='/*' element={<HomeRouter />} />
    </Routes>
  );
}

export default App;
