// src/containers/Home/HomeContainer.js
import { Flex, Heading, View, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import React from 'react';

// HomeContainer component
const HomeContainer = () => {
  const { tokens } = useTheme();

  return (
    <Flex
      direction='column'
      minHeight='100vh'
      backgroundColor={tokens.colors.background.primary}
    >
      <Flex flex='1'>
        <View padding={tokens.space.large}>
          <Heading level={1}>Welcome to Expert Sync</Heading>
          <p>Expert Sync Makes Data Science a Reality.</p>
        </View>
      </Flex>
    </Flex>
  );
};

export default HomeContainer;
