// src/router/HomeRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeContainer from '../containers/Home/HomeContainer';

const HomeRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeContainer />} />
    </Routes>
  );
};

export default HomeRouter;
